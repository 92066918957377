@import '../../assets/scss/_include-media.scss';

@include media("portrait") {
    #menu {
        width: 60vw;
        bottom: 5vw;
        button img { width: 7vw; }
    }
}

@include media("landscape") {
    #menu {
        width: 35vw;
        bottom: 3vw;
        button img { width: 4vw; }
    }
}

#menu {
    padding: 8px;

    display: flex;
    justify-content: space-around;
    align-items: center;

    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;

    border-radius: 90px;
    background: rgba(60, 72, 88, 0.9);
    box-shadow: rgba(0, 0, 0,0.1) 0px 0px 8px;
    
    opacity: 1;
    visibility: visible;
    transition: visibility 0s linear 0s,  opacity 0.1s linear;
    
    button { 
        padding: 0;
        
        img { 
            opacity: 0.5;
            padding: 8px;
        }
    }
    
    .item-selected button img { opacity: 1; }

}

.menu-hidden {
    opacity: 0 !important;
    visibility: hidden !important;
    transition: visibility 0s linear 0.2s,  opacity 0.1s linear !important;
}