#company-page {
    margin-bottom: -45px;

    section#header {
        height: 65vh;
        background-image: url(../../assets/img/company-page/copertina-azienda.jpg);
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;
    }
    
    section.rounded-page {
        position: relative;
        top: -45px;
        // background-color: rgba(255,255,255,0.9);

        h1 { 
            color: $primary;
            font-size: 2em;
            font-weight: bold;
        }
    
        article { margin-bottom: 50px; }

        article#azienda {
            ul { color: $primary; }
            p { margin: 0; }
            
        }
        
        article#numeri {

            background-color: #C1C828;
            border-radius: 34px;

            padding: 20px;

            color: #fff;
            text-align: center;

            img { 
                width: 45vw; 
                margin: 10px 0;
            }
            
            ul {
                padding: 0;
                list-style: none;

                li { 
                    margin: 30px 0; 

                    h1, h3 { 
                        margin: 0; 
                        color: #fff;
                    }
                    h3 { font-weight: 400; }
                    span { font-size: 0.8em; }
                }

            }
        }

        article#contact {

            h1 { text-align: center; }

            iframe {
                height: 200px;
                width: 100%;
                border: 0;
                border-radius: 34px;
            }

            #detail {
                font-size: 0.8em;
        
                margin: 20px 0;
                margin-top: 20px;

                div:nth-of-type(odd) { text-align: center; }

                div { 
                    padding: 10px; 
                
                    a {
                        color: #000;
                        text-decoration: underline;
                    }
                }
            }
        }

    }

}