#certification-page {
    margin-bottom: -80px;
    color: #000;

    h1 { 
        color: $primary; 
        font-size: 1.8em;
    }

    section#header {
        background: linear-gradient(180deg, rgba(236,239,178,1) 0%, rgba(221,227,94,1) 20%, rgba(199,206,59,1) 38%, rgba(184,191,35,1) 58%, rgba(194,200,66,1) 71%, rgba(228,231,172,1) 88%, rgba(255,255,255,1) 100%), #fff;
        padding: 100px 0;

        #logo-circle { 
            width: 100%;
            position: relative;
            top: -40px;
            
            #circle {
                width: 60vw;
                height: 60vw;
                
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                
                background: #fff;
                box-shadow: 0px 11px 21px rgba(0, 0, 0, 0.11), inset 0px 10px 63px rgba(0, 0, 0, 0.21), inset -18px -18px 30px #FFFFFF;
                border-radius: 100%;
                
                img { 
                    width: 40vw; 
                    
                    position: relative;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }   

    section.rounded-page {
        position: relative;
        top: -80px;

        section#qualita {

            article#youtube-video {

                margin: 30px 0;

                iframe {
                    width: 100%;
                    height: auto;
                    box-shadow: 6px 6px 13px -3px rgba(0,0,0,0.63);
                    border-radius: 30px;
                }

                div {
                    color: $primary;
                    padding: 15px 10px;
                }

                span { font-weight: bold; }

            }

        }
            
        section#certificazioni {

            section#cards {
                margin-top: 50px;

                article.card {
                    padding: 20px 30px;
                    text-align: center;
                
                    div { padding: 15px 0; }

                    img { width: 35vw; }
                    
                    a {
                        font-weight: 800;
                        text-decoration: underline;
                        color: $primary;
                    }
                }
            }
        }
    }

}