.dettaglio-fase-modal {
    $primary: #B8BF23;
    margin-bottom: -10vh;

    section#header {
        .image {
            position: relative;
            height: 40vh;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }
    }

    section.rounded-page {
        position: relative;
        top: -10vh;

        padding-top: 45px;

        // background: rgba(255, 255, 255, 0.85);
        
        h1 {
            font-size: 1.5em;
            text-align: center;
            margin-top: 0px;
            margin-bottom: 5px;
            color: $primary;
        }

        article { margin: 20px 0; }

        .content-a {

            .detail { 
                span { font-weight: 800; }

                >div:nth-child(2n + 1) { 
                    color: $primary; 
                    padding-bottom: 5px;
                }

                >div:nth-child(2n):not(:last-child) {
                    padding-bottom: 20px;
                }
                
                >div:last-child { font-size: 1.8em; }

                img { 
                    height: 15vw;
                    width: auto;
                    margin-right: 20px;
                }

            }
        }

        .content-b {

            .detail {
                font-size: 0.8em; 
                line-height: 19px;

                div:nth-child(2), div:nth-child(4) {
                    text-align: center;
                }

                div:nth-child(3) {
                    color: $primary; 
                    font-weight: 800;
                }
            }
        }

    }

}
