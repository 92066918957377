#product-page {
    margin-bottom: -80px;

    h3, h4 { color: $primary; }

    h1 {
        sup {
            font-size: 0.8em;
            margin-left: 3px;
        }
    }

    h3 {
        sup {
            font-size: 0.6em;
            margin-left: 5px;
        }
    }

    h3 { font-size: 1.8em; }

    section#header {
        background: linear-gradient(180deg, rgba(236,239,178,1) 0%, rgba(221,227,94,1) 20%, rgba(199,206,59,1) 38%, rgba(184,191,35,1) 58%, rgba(194,200,66,1) 71%, rgba(228,231,172,1) 88%, rgba(255,255,255,1) 100%), #fff;
        padding-bottom: 100px;

        #logo {
            img { width: 100%; }
        }

        h1#product-title {
            margin: 0;
            font-size: 1.5rem;
            color: #fff;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }

        .logo {
            position: relative;

            $size: 18vw;
            height: $size;
            width: $size;

            border-radius: 100%;
            background-color: #fff;

            img { 
                width: 75%; 
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        #product-image {
            position: relative;
            height: 100%;
            // margin-bottom: 30px;
    
            img {
                position: absolute;
                width: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
    
            span#circle {
                @include media("portrait") { 
                    height: 100%;
                    width: 150%; 
                }
                @include media("landscape") {
                    height: 120%;
                    width: 180%; 
                }

                position: absolute;
                top: 50%;
                left: 5%;
                transform: translateY(-50%);

                background: #fff;
                opacity: 0.83;
                box-shadow: 0px 11px 7px rgba(0, 0, 0, 0.11), inset 18px 18px 30px #D1D9E6, inset -18px -18px 30px #FFFFFF;
                border-radius: 100%;
            }
        }

        article#description {
            margin-top: 40px;

            p {
                span {  color: #fff; } 
            }
        }

    }

    section.rounded-page {
        position: relative;
        top: -80px;

        .image-slider {
            list-style: none;
            display: flex;
            flex-wrap: nowrap;
            overflow-y: auto;
            padding: 0;

            li {
                margin: 0 20px;
                text-align: center;

                p { 
                    font-size: 0.8em;
                    font-weight: 800;
                    color: #3c4858;
                    margin-top: 5px;
                }
            }

            li:first-child { margin-left: 32px; }
            li:last-child { margin-right: 32px; }
        }

        article {
            p { margin-top: 0; }
        }

        article#find-lot { margin-bottom: 50px; }

        #product-image {
            position: relative;
            text-align: right;
            
            img { width: 85%; }
    
            span.circle {
                position: absolute;
                width: 65vw;
                height: 65vw;
                left: -25vw;
                top: -6vw;
                z-index: -1;
                background: #fff;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.79) 16.15%, rgba(184, 191, 35, 0.79) 89.58%), #B8BF23;
                border-radius: 100%;
                box-shadow: 0px 11px 21px rgba(0, 0, 0, 0.24);
            }
        }

        article#accordion {
            margin: 50px 0;

            .MuiAccordion-root::before { 
                background: none;
            }

            .MuiAccordionDetails-root {
                background-color: #C1C828;
            }

            .MuiAccordionSummary-content {
                margin-left: calc(32px - 16px);

                // Icon and text alignment
                p { 
                    display: inline-flex;
                    align-items: center;
                    font-size: 0.9em;
                    img { 
                        margin-right: 20px; 
                        width: 2em;
                    }
                }
            }

            .MuiAccordionSummary-expandIcon {
                margin-right: calc(32px - 16px - 12px);
            }

            table#ingredienti {
                width: 100%;

                tr {
                    td { padding: 2px 0; }
                    td:nth-child(2) { 
                        width: 20%; 
                        text-align: center;
                    }
                }

                tfoot {
                    tr td { font-size: 0.8em; }
                    tr:first-child td {
                        padding-top: 30px
                    }
                }
            }

            table#valori-nutrizionali {
                width: 100%;
                
                tr {
                    td { padding: 2px 0; }
                    td:nth-child(2) {
                        width: 40%;
                        text-align: center;
                    }
                }
            }

            div#caratteristiche {
                .item {
                    margin: 10px 0;
                    .title, .value { margin: 2px 0; }
                    .title { color: #fff; }
                }
            }

        }

        article#logo-circle { 
            width: 80vw;
            height: 80vw;
            margin: 30px 0;

            position: relative;
            left: 10vw;

            img { 
                width: 60vw; 
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            #back-circle {
                width: 80vw;
                height: 80vw;
                border-radius: 100%;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.79) 16.15%, rgba(184, 191, 35, 0.79) 89.58%), #B8BF23;
                box-sizing: border-box;
                box-shadow: inset 20px 20px 30px #B8BF23, inset -20px -20px 30px #FFFFFF;
            }

            #front-circle {
                width: 60vw;
                height: 60vw;
                background-color: #fff;
                border-radius: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                box-shadow: 12px 11px 15px -5px #B8BF23;
            }

        }
    
        article#loghi-verdi {
            text-align: center;
            margin: 50px 0;
            .logo {
                img { margin: 10px; }
    
                .title, .subtitle { 
                    margin-bottom: 5px;
                    color: $primary;
                    line-height: 15px; 
                }
                
                .title { 
                    font-weight: bold;
                    font-size: 0.8em;
                }
                
                .subtitle { font-size: 0.7em; }
            }
        }

        article#ricette {
            margin: 60px 0;
        }

        article#green-e-green {
            #title {
                margin-top: 40px;
                text-align: center;
                img { width: 65%; }
            }

            h4 { margin: 40px 0; }

            section {
                margin: 60px 0;

                div.title {
                    color: $primary;
                    margin-bottom: 10px;
                    font-weight: 800;
                }
                ul.image-slider {
                    margin-top: 50px;
                    img { 
                        height: 70vw; 
                        width: auto;
                    }
                } 
            }
        }
    
    }

}