.find-lot-dialog .MuiDialog-paper {
    background-color: rgba(0,0,0,0.5);
}

.find-lot-dialog img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
