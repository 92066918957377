.dettaglio-fase {
    $primary: #B8BF23;

    width: 100%;

    // Carousel indicator margin FIX
    .Carousel-indicators-108 { margin-top: 0; }

    .container {
        $radius: 29px;

        overflow: hidden;
        height: 35vw;
        
        img { 
            width: 100%; 
            height: 100%;
            border-radius: $radius;
        }
        
        .text-container {
            border-radius: $radius 0 0 $radius;
            position: absolute;
            top: 0;
            width: 45%;
            height: inherit;

            display: flex;
            align-items: center;    

            background: linear-gradient(180deg, #ECEFB2 0%, rgba(236, 242, 114, 0) 77.08%), $primary;
            opacity: 0.9;

            .text {

                padding: 15px;

                h1 {
                    color: #fff !important;
                    font-size: 0.9em;
                    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                    text-align: left;
                    margin: 0;
                }

                p {
                    color: #fff;
                    font-size: 0.9em;
                    margin: 0;
                    padding: 10px 0;
                }

                img { 
                    width: 0.9em; 
                    height: 0.9em; 
                    margin-left: 5px;
                }

            }

        }
    }
}